import React from 'react'
import styled from 'styled-components'

const StyledArticle = styled.article`
  padding: 1rem 0 3rem;
  h2,
  h3,
  h4,
  h5 {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    color: #555555;
    max-width: 600px;
    margin: 4rem auto 1.5rem;
  }

  h1 {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    color: #555555;
    max-width: 600px;
    margin: 4rem auto 2rem;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  a,
  a:visited {
    color: #555555;
    background-image: linear-gradient(
      to bottom,
      transparent 65%,
      rgba(252, 224, 76, 0.6) 0%
    );
    background-repeat: no-repeat;
    text-decoration: none;
    transition: background-position 0.2s ease;
    background-size: 100% 250%;
    background-position: 0% 65%;
    :hover {
      background-position: 0% 100%;
      cursor: pointer;
    }
  }

  p {
    color: #555555;
    font-size: 17px;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    max-width: 600px;
    margin: 1rem auto;
    line-height: 1.6em;
  }

  ul,
  ol {
    margin: 2rem auto;
    padding-left: 2rem;
    max-width: 600px;
  }

  li {
    color: #555555;
    font-size: 17px;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    margin: 0.5rem 0;
    line-height: 1.5em;
  }

  blockquote > p {
    /* text-align: center; */
    /* width: 80% !important; */
    max-width: 600px !important;
    margin: 2rem auto 0rem !important;
    padding-left: 1rem;
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  blockquote > p:first-child:before {
    content: '”';
    position: relative;
    height: 0;
    display: inherit;
    line-height: 0;
    top: 90px;
    left: -80px;
    z-index: 0;
    opacity: 0.15;
    font-size: 350px;
    font-weight: bold;
    font-family: 'Inter', 'Times New Roman', Times, serif;
    @media (max-width: 600px) {
      left: -45px;
    }
  }

  blockquote {
    margin: 0;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }

  blockquote > p > em {
    font-size: 30px;
    line-height: 1.5;
    /* text-align: center; */
    font-style: normal;
    @media (max-width: 600px) {
      font-size: 26px;
    }
  }

  .twitter-tweet {
    margin: 50px auto 50px !important;
  }

  hr {
    max-width: 600px;
    margin: 4rem auto;
  }

  p > span {
    width: 1200px !important;
    max-width: 1200px !important;
    margin: 3rem -50% !important;
    @media (max-width: 1250px) {
      max-width: 100% !important;
      width: 100% !important;
      margin: 2rem auto !important;
    }
    @media (max-width: 600px) {
      max-width: 90vw !important;
      width: 90vw !important;
      margin: 2rem calc(-45vw + 50%) !important;
    }
  }

  p > video {
    max-width: 100%;
  }

  p > span > img {
    border-radius: 25px;
    @media (max-width: 600px) {
      border-radius: 15px;
    }
  }
  p > span > span {
    border-radius: 25px;
    @media (max-width: 600px) {
      border-radius: 15px;
    }
  }

  figure > span > span {
    border-radius: 25px;
    @media (max-width: 600px) {
      border-radius: 15px;
    }
  }

  figure {
    margin: 3rem 0 3rem;
  }

  figure > span > img {
    border-radius: 25px;
    @media (max-width: 600px) {
      border-radius: 15px;
    }
  }

  figcaption {
    margin-top: 0.5rem;
    color: #666666;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    text-align: center;
    font-style: italic;
  }
`

const Article = ({ children }) => <StyledArticle>{children}</StyledArticle>

export default Article
