import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/page'
import BlogHeader from '../components/blogHeader'
import Post from '../components/post'
import Date from '../components/date'
import BlogTitle from '../components/blogTitle'
import BlogIntro from '../components/blogIntro'
import PreviousText from '../components/previousText'
import Footer from '../components/footer'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { Helmet } from 'react-helmet'
import Article from '../components/article'

import posed from 'react-pose'
import styled from 'styled-components'

const PageTransitionStates = posed.div({
  startState: {
    opacity: 1,
    y: 0,
  },
  exitedState: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
    },
  },
})

const OverlayTransitionStates = {
  startState: {
    opacity: 1,
    transition: {
      duration: 300,
    },
  },
  exitedState: {
    opacity: 1,
    delay: 300,
    applyAtStart: { display: 'block' },
    transition: {
      duration: 300,
    },
  },
  visible: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    transition: {
      duration: 300,
    },
  },
}

function getTransitionStates(status) {
  if (['entering', 'entered'].includes(status)) {
    return 'visible'
  } else if (['exiting'].includes(status)) {
    return 'exitedState'
  } else if (['exited'].includes(status)) {
    return 'startState'
  } else if (['POP'].includes(status)) {
    return 'visible'
  }
}

const TransitionOverlay = styled(posed.div(OverlayTransitionStates))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #f6f6f6;
  opacity: 1;
`

const PostContainer = styled.div`
  margin: 8rem auto 3rem;
  max-width: 1200px;
  @media (max-width: 1250px) {
    margin: 3rem 2rem 3rem;
  }
  @media (max-width: 768px) {
    margin: 3rem 2rem 3rem;
  }
`

const ContentPage = styled.div`
  margin: 0 0 0;
  padding: 0 0 0;
`

const Posts = styled.div`
  width: 100vw;
  margin: 5rem 0 0;
  padding: 2rem calc((100vw - 1200px) / 2) 5rem;
  background: linear-gradient(90deg, rgb(246, 247, 248) 12px, transparent 1%)
      center,
    linear-gradient(rgb(246, 247, 248) 12px, transparent 1%) center, #d8d8d8;
  background-size: 15px 15px;
  @media (max-width: 1250px) {
    margin: 15rem 2rem 5rem;
    width: 100vw;
    margin: 5rem calc(-50vw + 50%) 0;
    padding: 2rem 2rem 15rem;
  }
`

const ArticleHTML = styled.article``

class BlogPostTemplate extends React.Component {
  render() {
    //   const post = this.props.data.markdownRemark;
    //   const siteTitle = this.props.data.site.siteMetadata.title;
    //   const author = this.props.data.site.siteMetadata.author;

    const { markdownRemark } = this.props.data // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark
    let heroImage = 'https://www.oscarnilsson.com/images/devices.jpg'
    if (frontmatter.heroImage) {
      heroImage = 'http://www.oscarnilsson.com' + frontmatter.heroImage
    }

    return (
      <ContentPage>
        <Page>
          <Helmet>
            <title>{frontmatter.title}</title>
            <meta property="og:title" content={frontmatter.title} />
            <meta property="og:description" content={frontmatter.intro} />
            <meta property="og:image" content={heroImage} />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content={'http://www.oscarnilsson.com' + frontmatter.path}
            />
            <script
              id="twitter-wjs"
              type="text/javascript"
              async
              defer
              src="//platform.twitter.com/widgets.js"
            />
          </Helmet>
          <TransitionState>
            {({ transitionStatus: status }) => {
              return (
                <PageTransitionStates pose={getTransitionStates(status)}>
                  <BlogHeader />
                  <PostContainer>
                    <BlogTitle>{frontmatter.title}</BlogTitle>
                    <Date>{frontmatter.date}</Date>
                    <BlogIntro>{frontmatter.intro}</BlogIntro>
                    <Article>
                      <ArticleHTML
                        dangerouslySetInnerHTML={{
                          __html: html,
                        }}
                      />
                    </Article>
                  </PostContainer>
                  <Posts>
                    <PreviousText>Recent notes</PreviousText>
                    {this.props.data.allMarkdownRemark.edges
                      .slice(0, this.props.data.allMarkdownRemark.edges.length)
                      .map(({ node }) => {
                        return <Post key={node.id} node={node} />
                      })}
                  </Posts>
                  <TransitionOverlay pose={getTransitionStates(status)} />
                </PageTransitionStates>
              )
            }}
          </TransitionState>
        </Page>
        <Footer dark={true} />
      </ContentPage>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD YYYY")
        path
        title
        intro
        heroImage
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { path: { ne: $path }, draft: { ne: true } } }
    ) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 160)
          frontmatter {
            date(formatString: "MMMM DD YYYY")
            title
            path
            intro
            heroImage
          }
        }
      }
    }
  }
`
