import React from 'react'
import styled from 'styled-components'

const StyledBlogTitle = styled.p`
  font-size: 70px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  z-index: 2;
  color: #333;
  margin: 0 auto;
  margin-bottom: 1rem;
  line-height: 1.2em;
  max-width: 100%;
  text-align: center;
  max-width: 60%;

  a,
  a:visited {
    color: #333;
  }
  a:hover {
    color: #666;
  }

  @media (max-width: 900px) {
    font-size: 35px;
    letter-spacing: 0px;
    max-width: 100%;
  }
`

const BlogTitle = ({ children }) => (
  <StyledBlogTitle>{children}</StyledBlogTitle>
)

export default BlogTitle
