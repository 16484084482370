import React from 'react'
import styled from 'styled-components'

const StyledBlogIntro = styled.p`
  font-size: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  z-index: 2;
  color: #333;
  margin: 0 auto;
  margin-bottom: 0rem;
  margin-top: 1rem;
  line-height: 1.2em;
  max-width: 600px;
  color: #999;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 25px;
    letter-spacing: 0px;
  }
`

const BlogIntro = ({ children }) => (
  <StyledBlogIntro>{children}</StyledBlogIntro>
)

export default BlogIntro
