import React from 'react'
import styled from 'styled-components'

const StyledDate = styled.div`
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 2.5rem;
  text-align: center;
`

const Date = ({ children }) => <StyledDate>{children}</StyledDate>

export default Date
