import React from 'react'
import styled from 'styled-components'

const StyledUnderline = styled.span`
  color: #4f4f4f;
  background-image: linear-gradient(
    to bottom,
    transparent 65%,
    rgba(50, 50, 50, 0.15) 10% /* rgba(35, 86, 155, 0.75) 10% */
  );
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-position 0.4s ease;
  background-size: 100% 250%;
  background-position: 0% 60%;
  :hover {
    background-position: 0% 100%;
    cursor: pointer;
  }
`

const Underlined = ({ children }) => (
  <StyledUnderline>{children}</StyledUnderline>
)

export default Underlined
