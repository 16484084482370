import React from 'react'
import styled from 'styled-components'
import TransitionLinkUnstyled from 'gatsby-plugin-transition-link'
import Underlined from '../components/underlined'
import Date from '../components/date'

const StyledPost = styled.div`
  margin: 2rem 0 3rem;
`

const TransitionLink = styled(TransitionLinkUnstyled)`
  text-decoration: none;
  color: #171d20;
  display: inline-block;
`

const Title = styled.h1`
  font-size: 60px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  z-index: 2;
  margin-bottom: 0.75rem;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 38px;
    letter-spacing: 0px;
  }
`

const Post = ({ node }) => (
  <StyledPost>
    <Title>
      <TransitionLink
        to={node.frontmatter.path}
        exit={{
          length: 0.6,
          zIndex: 2,
        }}
        entry={{
          delay: 0.6,
          length: 0.4,
        }}
      >
        <Underlined>{node.frontmatter.title}</Underlined>
      </TransitionLink>
    </Title>
    <Date>{node.frontmatter.date}</Date>
  </StyledPost>
)

export default Post
