import React from 'react'
import styled from 'styled-components'

const StyledPreviousText = styled.p`
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #777777;
  margin-bottom: 3rem;
  text-align: center;
`

const PreviousText = ({ children }) => (
  <StyledPreviousText>{children}</StyledPreviousText>
)

export default PreviousText
