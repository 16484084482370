import React from 'react'
import styled from 'styled-components'
import Button from '../components/button'

const BlogHeaderContainer = styled.div`
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  margin: 0 auto 3rem;
  padding: 6.45rem 5rem 0;
  background: linear-gradient(90deg, rgb(246, 247, 248) 12px, transparent 1%)
      center,
    linear-gradient(rgb(246, 247, 248) 12px, transparent 1%) center, #d8d8d8;
  background-size: 15px 15px;
  @media (max-width: 1650px) {
    padding: 6.45rem 2rem 0;
  }
  @media (max-width: 768px) {
    height: 400px;
    padding: 6.45rem 0rem 0;
    padding-bottom: 3rem;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`

const ButtonsTop = styled.div`
  min-width: 295px;
  @media (max-width: 768px) {
    display: block;
    width: calc(100% - 2rem);
    margin: 0 auto;
  }
`

const BlogDescription = styled.div`
  color: #5f5f5f;
  font-size: 27px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  max-width: 500px;
  margin: 0 0 2rem;
  line-height: 1.8rem;
  margin-right: 2rem;
  text-shadow: 0px 0px 3px rgb(246, 247, 248);
  @media (max-width: 768px) {
    font-size: 23px;
    width: calc(100% - 4rem);
    margin: 0 auto 2rem;
    text-align: center;
  }
`

const BelowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

let ticking = false
let lastScrollY = 0

class BlogHeader extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    lastScrollY = window.scrollY
    if (!ticking) {
      window.requestAnimationFrame(() => {
        this.nav.current.style.opacity = `${1 - (lastScrollY / 100) * 0.8}`
        ticking = false
      })

      ticking = true
    }
  }

  nav = React.createRef()

  render() {
    return (
      <BlogHeaderContainer ref={this.nav}>
        <TitleContainer>
          <BelowTitle>
            <BlogDescription>
              Notes and thoughts about designing human interfaces and products.
            </BlogDescription>
          </BelowTitle>
          <ButtonsTop>
            <Button
              onPressed={() => {
                window.open('https://www.twitter.com/@oscrse')
              }}
              showArrow={true}
            >
              Get updates on Twitter
            </Button>
          </ButtonsTop>
        </TitleContainer>
      </BlogHeaderContainer>
    )
  }
}

export default BlogHeader
